/* ============================================================
 * Woocommerce Notices
============================================================ */

/**
	* Primary styling for Woocommerce Notices
*/

.c-messages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: rgba($black, 0.5);
    z-index: 1000;
    @include transition(opacity);

    .c-messages__inner {
	    max-height: 90vh;
	    overflow: scroll;
    }

    .c-messages__notice {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column-reverse;
	    color: $colour-primary;

        a {
            width: auto;
            display: inline-block;
            margin-top: 20px;
            @extend .e-btn;
            @extend .e-btn--primary;
	        @extend .e-btn--full;
        }
    }


    .c-messages__close {
	    @include transition(transform);
        position: absolute;
        top: 15px;
        right: 15px;
	    color: $colour-primary;
	    cursor: pointer;

	    &:hover {
		    transform: rotate(90deg);
	    }
    }


    &.isHidden {
        pointer-events: none;
        opacity: 0;
    }
}

.woocommerce-NoticeGroup-checkout {
	width: 100%;
	padding-left: 60px;
	margin-bottom: $spacing-base;

	ul {
		background-color: $white;
		margin: 0;
		padding: $spacing-base;

		li {
			list-style: none;
			padding: 0;
			margin-left: 0;
		}
	}

	.woocommerce-error {
		strong { color: $error; }
	}
}
