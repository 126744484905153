.c-banner {
	&--standard {
		.c-banner__item {
			position: relative;

			&.no-image {
				position: absolute;
				background-color: $colour-primary;
			}
		}
	}

	&__item {
		position: relative;
		z-index: 99;

		@include by-device(ipad-pro) {
			@include cover();
			z-index: 99;
		}

		&.no-image {
			position: absolute;
			background-color: $colour-primary;
		}
	}
}
