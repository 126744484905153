/* ============================================================
	* Base - Text
============================================================ */

/**
 * Default paragpraph text
 *
*/

p {
	color: $black;
	margin: 0;
	padding: 0;
	font-size: $font-size-body;
	line-height: $line-height-body;
}


h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}