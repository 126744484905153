.c-category {
	img { @include transition(transform); }

	> .e-media {
		@include aspect-ratio(1,1);
		display: none;

		@include by-device(iphone-max) {
			display: flex;
		}
	}

	&:hover {
		img { transform: scale(1.1); }

		.e-link {
			text-decoration: underline;
		}
	}
}
