/* ============================================================
	* Elements - Media
============================================================ */

/**
 * Media styling for images and videos
 *
 */

.e-media {
	width: 100%;
	height: auto;
	display: flex;
}





/* Media Variations
============================================================ */

.e-media--cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100% !important;
	object-fit: cover;
	pointer-events: none;
}


.e-media--radius {
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	overflow: hidden;
}


.e-media--contain { object-fit: contain; }


.e-media--video {
	@include aspect-ratio(16,9);

	iframe, video {
		opacity: 0;
		pointer-events: none;
		@include cover(99);
		@include transition(opacity);

		&.isActive {
			pointer-events: auto;
			opacity: 1;
		}
	}

}





/* Media Sizes
============================================================ */

.e-media--1-1 { @include aspect-ratio(1,1); }
.e-media--3-2 { @include aspect-ratio(3,2); }
.e-media--4-3 { @include aspect-ratio(4,3); }
.e-media--16-9 { @include aspect-ratio(16,9); }
.e-media--266-100 { @include aspect-ratio(266,100); }
