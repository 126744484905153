/* ==========================================================================
   * Settings - Spacing
   ========================================================================== */

/**
 *  Set the global space variables to be used on a project. Also we can set
 *  the array of values to generate our helper spacer classes.
 *
 */

/* Default Spacing Values
============================================================ */

$spacing-base: 20px;
$spacing-quarter: math.div($spacing-base, 4);
$spacing-half: math.div($spacing-base, 2);
$spacing-2x: $spacing-base * 2;
$spacing-3x: $spacing-base * 3;
$spacing-4x: $spacing-base * 4;
$spacing-5x: $spacing-base * 5;
$spacing-6x: $spacing-base * 6;





/* Container Settings
============================================================ */

$container-width: 1280px;
$container-width-wide: 1620px;





/* Row Spacing Array ( For helper classes )
============================================================ */

$rows: (
	default: (
		default: $spacing-2x,
		ipad: $spacing-3x,
	),
	small: (
		default: $spacing-base,
		ipad: $spacing-2x
	),
	none: (
		default: 0
	)
);





/* Spacing Sizes Array ( For helper classes )
============================================================ */

$sizes: (
	null: $spacing-base,
	'-quarter': $spacing-quarter,
	'-half': $spacing-half,
	'-2x': $spacing-2x,
	'-3x': $spacing-3x,
	'-4x': $spacing-4x,
	'-5x': $spacing-5x,
	'-6x': $spacing-6x,
	'-7x': $spacing-base * 7,
	'-8x': $spacing-base * 8,
	'-9x': $spacing-base * 9,
	'-10x': $spacing-base * 10,
	'-0': 0
);





/* Spacing Direction Array ( For helper classes )
============================================================ */

$directions: (
	null: null,
	't':'-top',
	'b':'-bottom',
	'l':'-left',
	'r':'-right',
	'y':'-y',
	'x':'-x',
);





/* Spacing Properties Array ( For helper classes )
============================================================ */

$properties: (
  "p": "padding",
  "m": "margin",
);
