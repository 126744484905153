/* ============================================================
	* Utilities - Trumps
============================================================ */

/**
 * A trumps files where overiding styling is placed,
 * this is the only place in a project that a !important
 * should be used !
 */

.t-trustpilot { transform: translateY(2px); }
.t-splide-overflow { overflow: visible !important; }

.splide__arrow { transition: opacity .3s ease; }

.splide__arrow:disabled {
	opacity: 0.2;
	pointer-events: none;
}

.u-font-size-32 {
	font-size: 32px;
}