/* ==========================================================================
   * Settings - Colours
========================================================================== */

/**
 * The global colour settings for the project including
 * the palette and the global colour settings.
 *
 */


/* Colour Palette
============================================================ */

$colour-primary: #0058A5;
$colour-secondary: #EFF4F9;
$colour-tertiary: #BFD5E8;
$colour-quaternary: #591862;






/* Colour Defaults
============================================================ */

$black: #000;
$white: #fff;





/* Colour Neutrals
============================================================ */

$grey-light: #F7F7F7;
$grey: #EDEDED;





/* Colour States
============================================================ */

$success: #25AF60;
$error: #F20C49;





/* Colour Gradients
============================================================ */

$gradient: linear-gradient(180deg, $colour-secondary 0%, $white 100%);





/* Colour Settings
============================================================ */

$page-background-colour: $white;
$darken-opacity: 0.5;
$darken-colour: $black;
