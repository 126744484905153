/* ============================================================
	* Base - Links
============================================================ */

/**
 * Default link styling bassed of settings
 * set in the main settings file.
 *
 */

a {
	color: $colour-primary;
	text-decoration: none;
}