/* ============================================================
	* Tools - Cover
============================================================ */

/**
 * The global mixin for covering over something 100%.
 * Example:
 * @include cover(2);
 *
 */

@mixin cover($z-index: 1) {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $z-index;
}