.c-post {
	&__inner {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 99;
	}

	&:hover {
		.u-darken { opacity: 0.5; }
	}
}
