/* ==========================================================================
	Moleqular, by Purpose Media
========================================================================== */

/*! purgecss start ignore */
@use "sass:math";

/* Settings
========================================================================== */

@import 'settings/colours';
@import 'settings/typography';
@import 'settings/breakpoints';
@import 'settings/spacing';
@import 'settings/grid';
@import 'settings/inputs';
@import 'settings/buttons';
@import 'settings/globals';





/*  Tools ( Mixins
========================================================================== */

@import 'tools/media-query';
@import 'tools/aspect-ratio';
@import 'tools/cover';
@import 'tools/transition';
@import 'tools/heading';





/*  Generic - Rest, Normalize
========================================================================== */

@import 'sanitize';





/*  Packages ( NPM, External etc...
========================================================================== */

@import '../../node_modules/@splidejs/splide/src/css/core/index.scss';




/*  Base
========================================================================== */

@import 'base/page';
@import 'base/text';
@import 'base/links';
@import 'base/images';
@import 'base/inputs';
@import 'base/lists';





/*  Objects
========================================================================== */

@import 'objects/row';
@import 'objects/container';
@import 'objects/layout';
@import 'objects/list-bare';





/*  Elements
========================================================================== */

@import 'elements/link';
@import 'elements/button';
@import 'elements/heading';
@import 'elements/media';
@import 'elements/slider-arrow';
@import 'elements/form';
@import 'elements/social';
@import 'elements/pagination';
@import 'elements/select';



/*  Components
========================================================================== */

@import 'components/header';
@import 'components/menu';
@import 'components/banner';
@import 'components/promotion';
@import 'components/brand';
@import 'components/comparison';
@import 'components/editable';
@import 'components/post';
@import 'components/card';
@import 'components/accordion';
@import 'components/download';
@import 'components/tab';




/*  WooCommerce
========================================================================== */
@import 'components/woocommerce/vat-switch';
@import 'components/woocommerce/product';
@import 'components/woocommerce/product-archive';
@import 'components/woocommerce/category';
@import 'components/woocommerce/single-product';
@import 'components/woocommerce/cart';
@import 'components/woocommerce/woocommerce';
@import 'components/woocommerce/order-received';
@import 'components/woocommerce/checkout';
@import 'components/woocommerce/dashboard';
@import 'components/woocommerce/woocommerce-notices';
@import 'components/woocommerce/login';
@import 'components/woocommerce/woocommerce-filters';





/*  Utilities
========================================================================== */
/*! purgecss end ignore */

@import 'utilities/widths';
@import 'utilities/heights';
@import 'utilities/spacing';
@import 'utilities/flex';
@import 'utilities/trumps';
@import 'utilities/helpers';
