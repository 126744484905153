.c-tab {
	&__parent {
		@include transition(color, background-color);
		color: $colour-quaternary;
		background-color: $white;
		cursor: pointer;

		&:hover {
			color: $white;
			background-color: $colour-quaternary;
		}

		&.active {
			color: $white;
			background-color: $colour-quaternary;
			cursor: default;
		}
	}

	&__item {
		background-color: $white;
		border: 1px solid $colour-quaternary;
		display: none;

		&.active {
			display: block;
		}
	}
}
