.c-menu {

    &__children {
        @include transition(opacity);
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: $gradient;
        border-radius: $border-radius;
        opacity: 0;
	    z-index: 999;
	    box-shadow: 0 100px 100px rgba(0, 0, 0, 0.2);
    }

    &__item {
	    @include transition(background-color);
        white-space: nowrap;

	    &--sale {
		    background-color: $error !important;
		    border-top: 0 !important;

		    &:hover {
			    background-color: darken($error, 5%) !important;

			    .c-menu__link {
				    color: $white !important;
			    }
		    }
	    }

        &:hover {
	        background-color: $colour-secondary;

	        .c-menu__link { color: $colour-primary; }

            .c-menu__children {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

	&__link {
		@include transition(color);
		color: $white;
	}

	&__darken {
		@include cover();
		position: fixed;
		background-color: red;
		z-index: 98;
	}

	&--secondary {
		@include transition(max-height, height);
		max-height: 0;
		height: 0;

		&.active {
			max-height: 100px;
			height: 100%;
		}
	}

	&--mobile {
		@include transition(opacity, right);
		background-color: $white;
		position: fixed;
		top: 0;
		right: 100%;
		width: 100%;
		z-index: 999;
		opacity: 0;
		pointer-events: none;
		box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);

		&.active {
			opacity: 1;
			right: 0;
			pointer-events: all;
		}

		.c-menu__trigger {
			@include transition(transform);

			&.active {
				transform: rotate(180deg);
			}
		}

		.c-menu__item {
			background-color: $colour-primary;
			border-top: 1px solid darken($colour-primary, 10%);

			&:first-of-type {
				border-top: none !important;
			}
		}

		.c-menu__child {
			background-color: $colour-tertiary;
			border-top: 1px solid darken($colour-tertiary, 10%);

			&:first-of-type {
				border-top: none !important;
			}
		}

		.c-menu__grand-child {
			background-color: $colour-secondary;
			border-top: 1px solid darken($colour-secondary, 10%);

			&:first-of-type {
				border-top: none !important;
			}
		}
	}
}
