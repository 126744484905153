.woocommerce-cart {

	// Global Cart Wrapper
	.woocommerce {

		.screen-reader-text {
			display: none;
		}

		small {
			font-size: 8px;
		}
	}





	// Keys style
	.woocommerce-cart-form {
		thead {
			width: 100%;
			display: block;
			background-color: $colour-primary;
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
			padding: $spacing-half 0;
			display: none;

			@include by-device(ipad-pro) {
				display: block;
			}

			tr {
				display: flex;
				text-align: center;
			}

			th {
				font-weight: $font-bold;
				font-size: $font-size-small;
				margin-right: $spacing-base;
				padding: 0;
				margin: 0;
				color: $white;
			}
		}
	}





	// Cart Items
	.woocommerce-cart-form__contents {
		display: block;

		tbody {
			display: block;

			tr {
				width: 100%;
				display: block;

				@include by-device(ipad-pro) {
					display: flex;
				}
			}

			tr:last-of-type {
				display: block;
				width: 100%;


				td {
					padding: 0;
				}
			}

			tr:first-of-type {
				border-top-left-radius: $border-radius;
				border-top-right-radius: $border-radius;

				@include by-device(ipad-pro) {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
			}

			tr:nth-last-of-type(2) {
				overflow: hidden;
				margin-bottom: $spacing-base;
				border-bottom-left-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
			}

			td {
				padding: $spacing-base;
				display: flex;
				align-items: center;
				justify-content: center;

				@include by-device(ipad-pro) {
					border-right: 1px solid $colour-primary;
					padding: $spacing-half 0;
				}

				&.actions {
					justify-content: flex-start;
					border: 0;

					.coupon {
						display: none !important;
					}

					button {
						@extend .e-btn;
						@extend .e-btn--primary;
						border: 0;
					}
				}
			}
		}

		td, th {
			padding-right: $spacing-base;
			padding-left: $spacing-base;
			text-align: center;

			&.product-thumbnail {
				width: 100px;
				margin: 0 auto;

				img {
					height: 75px;
					width: 75px;
					object-fit: contain;
				}
			}

			&.product-name {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				//justify-content: flex-start;

				@include by-device(ipad-pro) {
					justify-content: flex-start;
				}

				a {
					margin-left: $spacing-base;
					margin-right: $spacing-base;
					color: $black;
					text-align: center;

					@include by-device(ipad-pro) {
						text-align: left;
					}
				}

				.variation {
					width: 100%;
					margin-bottom: 0;
					margin-left: $spacing-base;
					margin-left: $spacing-base;
					text-align: center;

					@include by-device(ipad-pro) {
						text-align: left;
					}

					dt { font-weight: $font-bold; }
					dd { margin-left: 0; }
				}

				@include by-device(ipad-pro) {
					width: 30%;
				}
			}

			&.product-price  {
				width: 100%;

				@include by-device(ipad-pro) {
					width: 20%;
				}
			}

			&.product-quantity  {
				width: 100%;

				@include by-device(ipad-pro) {
					width: 20%;
				}
			}

			&.product-subtotal  {
				width: 100%;

				@include by-device(ipad-pro) {
					width: calc(30% - 175px);
				}
			}
		}


		.product-remove {
			width: 75px;
			order: 6;
			border-right: 0;
			margin: 0 auto;

			a {
				@include transition(background-color, color);
				color: $white;
				width: 30px;
				height: 30px;
				background-color: $error;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 30px;

				&:hover {
					background-color: darken($error, 10);
				}
			}
		}
	}

	.cart_item {
		width: 100%;
		border: 1px solid $colour-primary;
		background-color: $white;

		@include by-device(ipad-pro) {
			display: flex;
		}

		input.qty {
			width: 100%;
			min-width: 100px;
			max-width: 100px;
		}

		@include by-device(ipad-pro) {
			border-top: 0;
		}
	}




	// Totals
	.cart_totals  {
		padding: $spacing-base;
		text-align: left;
		margin-top: $spacing-base;
		@extend .c-card;
		@extend .c-card--primary;

		@include by-device(laptop) {
			padding: $spacing-2x;
			width: 50%;
			margin-left: auto;
		}

		th { white-space: nowrap; }

		.woocommerce-shipping-calculator {
			margin-top: $spacing-base;
			text-align: left;
			text-decoration: underline;

			@include by-device(ipad-pro) {
				text-align: right;
			}

			section {
				margin-top: 10px;
			}

			a {
				color: $white;
			}

			select {
				@extend .e-select;
				@extend .e-select--white;
			}

			input {
				width: 100%;
				margin-top: $spacing-half;
			}

			button {
				@extend .e-btn;
				@extend .e-btn--white;
				margin-top: $spacing-base;
				width: 100%;
				border: 0;
				color: $colour-primary;
			}
		}

		.shop_table {
			display: block;
			width: 100%;

			tbody {
				display: block;
				width: 100%;
			}

			tr {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin-top: $spacing-half;

				@include by-device(ipad-pro) {
					flex-wrap: nowrap;
					width: auto;
				}
			}

			th {
				display: block;
				margin-right: $spacing-base;
				color: $white;
				width: 100%;

				@include by-device(ipad-pro) {
					width: auto;
				}
			}

			td {
				color: $white;
			}

			.woocommerce-remove-coupon {
				font-size: $font-size-small;
				color: $white;
				@include transition(color);

				&:hover {
					color: darken($white, 10);
				}
			}
		}

		h2 {
			margin-bottom: $spacing-base;
			@extend .e-heading;
			@extend .e-heading--white;
			@extend .e-heading--three;
			@extend .e-heading--four\@ipad;
			text-transform: capitalize;
		}

		.shipping {

			ul {
				@extend .o-list-bare;

				li {
					@extend .o-list-bare__item;
					text-align: left;

					@include by-device(ipad-pro) {
						text-align: right;
					}

					label {
						margin-left: $spacing-half
					}
				}
			}

			.woocommerce-shipping-destination {
				@extend .u-font-small;
				margin-top: $spacing-half;
				color: $white;
				font-style: italic;
			}
		}

		.wc-proceed-to-checkout {
			a {
				margin-top: $spacing-base;
				@extend .e-btn;
				@extend .e-btn--buy;
				@extend .e-btn--full;
			}
		}
	}

	// Emprt Cart Styles
	.cart-empty {
		margin-bottom: $spacing-base;
		@extend .e-heading;
		@extend .e-heading--primary;
		@extend .e-heading--five;
		@extend .e-heading--four\@ipad;
		@extend .e-heading--regular
	}


	.return-to-shop {
		a {
			@extend .e-btn;
			@extend .e-btn--primary;
		}
	}
}
