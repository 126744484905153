/* ============================================================
	* Utilities - Flex
============================================================ */

/**
 * Simple flex helpers to change a flex layout throughout
 * device sizes.
 * Example:
 * u-flex  u-flex-wrap  u-flex-no-wrap@ipad
 *
 */

.u-flex { display: flex; }
.u-flex-inline { display: inline-flex; }
.u-show { display: inline-block; }
.u-hide { display: none; }
.u-space-between { justify-content: space-between; }
.u-space-around { justify-content: space-around; }
.u-space-evenly { justify-content: space-evenly; }
.u-v-top { align-items: flex-start; }
.u-v-bottom { align-items: flex-end; }
.u-v-center { align-items: center; }
.u-h-left { justify-content: flex-start; }
.u-h-right { justify-content: flex-end; }
.u-h-center { justify-content: center; }
.u-no-wrap { flex-wrap: nowrap; }
.u-wrap { flex-wrap: wrap; }
.u-block { display: block; }
.u-js-right { justify-self: right; }
.u-js-left { justify-self: left; }
.u-as-top { align-self: flex-start; }
.u-as-center { align-self: center; }
.u-as-bottom { align-self: flex-end; }
.u-flex-reverse { flex-direction: row-reverse; }
.u-flex-even { flex: 1; }


@each $device, $breakpoint in $breakpoints {
	@media (min-width: $breakpoint) {
		.u-flex\@#{$device} { display: flex; }
		.u-flex-inline\@#{$device} { display: inline-flex; }
		.u-show\@#{$device} { display: inline-block; }
		.u-hide\@#{$device} { display: none; }
		.u-space-between\@#{$device} { justify-content: space-between; }
		.u-space-around\@#{$device} { justify-content: space-around; }
		.u-space-evenly\@#{$device} { justify-content: space-evenly; }
		.u-v-top\@#{$device} { align-items: flex-start; }
		.u-v-bottom\@#{$device} { align-items: flex-end; }
		.u-v-center\@#{$device} { align-items: center; }
		.u-h-left\@#{$device} { justify-content: flex-start; }
		.u-h-right\@#{$device} { justify-content: flex-end; }
		.u-h-center\@#{$device} { justify-content: center; }
		.u-no-wrap\@#{$device} { flex-wrap: nowrap; }
		.u-wrap\@#{$device} { flex-wrap: wrap; }
		.u-block\@#{$device} { display: block; }
		.u-js-right\@#{$device} { justify-self: right; }
		.u-js-left\@#{$device} { justify-self: left; }
		.u-as-top\@#{$device} { align-self: flex-start; }
		.u-as-center\@#{$device} { align-self: center; }
		.u-as-bottom\@#{$device} { align-self: flex-end; }
		.u-flex-reverse\@#{$device} { flex-direction: row-reverse; }
		.u-flex-even\@#{$device} { flex: 1; }
	}

}
