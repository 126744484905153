/* ============================================================
	* Element - Headings
============================================================ */

/**
 * The styling for headings across the site you can edit
 * the sizing in the typography config under settings.
 *
 */

.e-heading {
	margin: 0;
	padding: 0;
	color: $black;
	font-family: $font-family-primary;
	font-weight: $heading-weight;
}





/* Heading Sizes
============================================================ */

h1, .e-heading--one {
	@if ($automatic-mobile-sizes) {
		@include heading(three);

		@include by-device(ipad) {
			@include heading(two);
		}

		@include by-device(ipad-pro) {
			@include heading(one);
		}
	} @else {
		@include heading(one);
	}
}


h2, .e-heading--two {
	@if ($automatic-mobile-sizes) {
		@include heading(three);

		@include by-device(ipad-pro) {
			@include heading(two);
		}
	} @else {
		@include heading(two);
	}
}


h3, .e-heading--three {
	@if ($automatic-mobile-sizes) {
		@include heading(four);

		@include by-device(ipad-pro) {
			@include heading(three);
		}
	} @else {
		@include heading(three);
	}
}


h4, .e-heading--four {
	@if ($automatic-mobile-sizes) {
		@include heading(five);

		@include by-device(ipad-pro) {
			@include heading(four);
		}
	} @else {
		@include heading(four);
	}
}


h5, .e-heading--five {
	@include heading(five);
}


h6, .e-heading--six {
	@include heading(six);
}





/* Responsive Headings
============================================================ */

@each $device, $breakpoint in $breakpoints {
	@media (min-width: $breakpoint) {
		.e-heading--one\@#{$device} { @include heading(one); }
		.e-heading--two\@#{$device} { @include heading(two); }
		.e-heading--three\@#{$device} { @include heading(three); }
		.e-heading--four\@#{$device} { @include heading(four); }
		.e-heading--five\@#{$device} { @include heading(five); }
		.e-heading--six\@#{$device} { @include heading(six); }
	}
}





/* Heading Colour
============================================================ */

.e-heading--white { color: $white; }
.e-heading--black { color: $black; }
.e-heading--primary { color: $colour-primary; }
.e-heading--secondary { color: $colour-secondary; }
.e-heading--tertiary { color: $colour-tertiary; }
.e-heading--quaternary { color: $colour-quaternary; }





/* Heading Weights
============================================================ */

.e-heading--light { font-weight: $font-light; }
.e-heading--regular { font-weight: $font-regular; }
.e-heading--medium { font-weight: $font-medium; }
.e-heading--semi { font-weight: $font-semi; }
.e-heading--bold { font-weight: $font-bold; }
