/* ============================================================
	* Elements - Select
============================================================ */

/**
 * A simple select to replace the browser default select
 *
 */

.e-select {
	@include transition(border-color);
	font-family: $font-family-primary;
	border-radius: $border-radius;
	border: $inputs-border;
	background-color: $inputs-background;
	color: $inputs-colour;
	padding: $inputs-spacing;
	font-size: $input-font-size;
	line-height: $input-line-height;
	outline: 0;
	appearance: none;
	font-style: normal;
	width: 100%;
	cursor: pointer;
	max-width: 100%;
	font-weight: $font-regular;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 8'%3E%3Cdefs/%3E%3Cpath fill='%23575756' fill-rule='evenodd' d='M2.1.3l4.6 4.6L11.3.3l1.4 1.4-6 6-6-6L2.1.3z'/%3E%3C/svg%3E");
	background-position: right 20px top 50%, 0 0;
	background-repeat: no-repeat;
	background-size: 10px auto;
	box-shadow: $box-shadow-small;
}


.e-select::-ms-expand { display: none; }


.e-select:focus {
	outline: 0;
	border-color: $colour-primary;
}





/* Select Colours
============================================================ */

.e-select--white {
	background-color: $white;
	border: 1px solid $colour-primary;
}


.e-select--grey {
	background-color: $grey-light;
	border: 1px solid $grey-light;
}





/* Select Others
============================================================ */

.e-select--fake {
	opacity: 0.4;
	pointer-events: none;
}


.e-select--disabled {
	opacity: 0.6;
	pointer-events: none;
}
