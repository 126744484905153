/* ==========================================================================
	* Settings - Inputs
========================================================================== */

/**
 * Settings for our buttons, set up our style here for what we
 * want our buttons to look like.
 *
 */

$button-weight: $font-bold;
$button-spacing: 17px $spacing-base;
$button-font-size: $font-size-body;
$button-line-height: $line-height-body;
