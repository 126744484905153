.woocommerce-order {

	.order_details + p {
		color: $colour-primary;
		margin-bottom: $spacing-base;
		font-family: $font-family-primary !important;

		a {
			// font-weight: $font-bold !important;
			text-decoration: underline;
			color: $colour-primary;
		}
	}


	.woocommerce-thankyou-order-received {
		@extend .u-content-width;
		@include heading(one);
		margin-bottom: $spacing-base;
		text-align: left;
		color: $colour-primary !important;
		font-weight: $font-bold;

		@include by-device(ipad) {
			// margin-top: $spacing-5x;
		}

		@include by-device(ipad-pro) {
			margin-bottom: $spacing-2x;
			// margin-top: $spacing-3x * 2;
			text-align: left;
			@include heading(two);
		}
	}

	.woocommerce-order-overview {
		padding: $spacing-base;
		background-color: $white;
		border-radius: $border-radius;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: $spacing-base !important;
		box-shadow: 0 2px 34px 0 rgba($black, 0.05);
		border: 1px solid $grey-light;
		width: 100%;
		@extend .o-layout;

		@include by-device(ipad) {
			padding: $spacing-2x;
		}

		li {
			width: 100%;
			border-right: 0 !important;
			margin-top: $spacing-half;
			margin-left: 0 !important;
			margin-right: $spacing-base;
			@extend .o-layout__item;

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-right: 0;
			}

			@include by-device(laptop) {
				margin-top: 0;
				border-right: inherit;
				width: auto;
			}

			strong {
				color: $colour-primary;
			}
		}
	}

	.wc-item-meta {
		@extend .o-list-bare;
		margin-top: $spacing-base;

		li {
			@extend .o-list-bare__item;
			text-align: left !important;
			display: flex;
			margin-top: 0 !important;

			strong {
				margin-right: $spacing-quarter;
			}
		}
	}

	.woocommerce-order-details,
	.woocommerce-customer-details {
		border: 1px solid $grey-light;
		background-color: $white;
		padding: $spacing-base;
		border-radius: $border-radius;
		margin-bottom: $spacing-base;
		box-shadow: 0 2px 34px 0 rgba($black, 0.05);

		@include by-device(ipad) {
			padding: $spacing-2x;
		}

		h2 { margin-bottom: $spacing-base; }

		&__title,
		header h2 {
			color: $colour-primary;
			@include heading(four);
		}

		header h2 {
			margin-top: $spacing-3x;
			margin-bottom: $spacing-base;
		}

		.product-quantity {
			font-weight: $font-regular;
		}

		.woocommerce-table--order-details,
		.woocommerce-orders-table--subscriptions {
			margin-top: $spacing-2x;
			border: 0;
			border-radius: 0;
			border: 1px solid $colour-primary;

			thead tr th {
				color: $white;
				width: 50%;

				&:last-of-type {
					padding-left: $spacing-base;
				}
			}

			tfoot {

				tr {
					border-top: 1px solid $colour-primary;
					padding: $spacing-quarter 0;
				}

				th {
					color: $colour-primary;
					width: 50%;
				}
				td {
					border-top: 1px solid $grey-light;
					font-weight: $font-regular;
					width: 50%;
					padding-left: $spacing-base;
				}
			}

			th { text-align: left; }

			tbody {
				padding: 0;

				td {
					border-top: 1px solid $grey-light;
					width: 50%;
					padding: $spacing-base;
				}
			}

			th, td {
				padding-left: 0;
			}
		}
	}

	.woocommerce-columns--addresses {
		width: 100%;
	}

	.woocommerce-customer-details {

		.woocommerce-columns--addresses {
			display: flex;

			.woocommerce-column--billing-address,
			.woocommerce-column--shipping-address {
				width: 50% !important;

				h2 {
					@include heading(four);
					color: $colour-primary !important;
				}

				address {
					margin-top: $spacing-base !important;
					padding: 0 !important;
					border: 0 !important;
				}
			}
		}
	}

	.woocommerce-orders-table__cell-subscription-actions {
		a {
			@extend .e-btn;
			@extend .e-btn--primary;
		}
	}
}
