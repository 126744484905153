/* ==========================================================================
   * Settings - Inputs
   ========================================================================== */

/**
 *  Settings for our inputs, set up our style here for what we
 *  want our inputs to look like.
 *
 */

$inputs-border: 1px solid $colour-primary;
$inputs-colour: $colour-primary;
$inputs-placeholder: $colour-primary;
$inputs-background: $white;
$inputs-spacing: 11px $spacing-base;
$input-font-size: $font-size-body;
$input-line-height: $line-height-body;
