.c-promotion {

	min-height: 300px;

	&__description {
		transition: max-height 400ms ease-in-out, opacity 400ms ease-in-out;

		@include by-device(laptop) {
			max-height: 0;
			opacity: 0;
		}
	}

	&:hover {
		@include by-device(laptop) {
			.c-promotion__description {
				max-height: 500px;
				opacity: 1;
			}

			.u-darken { opacity: $darken-opacity; }
		}
	}
}
