/* ============================================================
 * Woocommerce Dashboard
============================================================ */

/**
	* Primary styling for the Woocommerce dashboard
	* and account pages.
*/

.c-woocommerce--dashboard {

	.woocommerce-ResetPassword {
		@extend .u-content-width;
		background-color: $white;
		border: 1px solid $grey-light;
		padding: $spacing-base;

		@include by-device(ipad-pro) {
			padding: $spacing-2x;
		}

		p {
			&:first-of-type {
				@include heading(four);
				font-weight: $font-bold;
				color: $colour-primary;
			}
		}

		.woocommerce-form-row {
			margin-top: $spacing-base;
			display: flex;
			flex-wrap: wrap;

			label {
				width: 100%;
				font-weight: $font-bold;
				color: $colour-primary;
				text-transform: uppercase;
			}

			input {
				width: 100%;
				margin-top: $spacing-half;
			}


		}
	}

	p {
		width: 100%;
	}

	.woocommerce {
		display: flex;
		flex-wrap: wrap;

		.woocommerce-MyAccount-navigation {
			top: $spacing-2x;
			position: sticky;
			background-color: $colour-primary;
			padding: $spacing-base;
			align-self: flex-start;
			width: 100%;

			@include by-device(ipad-pro) {
				padding: $spacing-2x;
				margin-right: $spacing-2x;
				width: calc(25% - $spacing-base);
			}
		}

		.woocommerce-MyAccount-content {
			width: 100%;
			background-color: $white;
			border: 1px solid $grey-light;
			padding: $spacing-base;
			margin-top: $spacing-base;
			@extend .c-editable;

			@include by-device(ipad-pro) {
				margin-top: 0;
				padding: $spacing-2x;
				width: calc(75% - $spacing-2x);
			}
		}
	}

	input, textarea {
		padding: 10px 20px;
		font-size: 16px;
		outline: 0;
		line-height: 24px !important;
		background-color: $white;
	}

	button.button, a.button, button {
		@extend .e-btn;
		@extend .e-btn--primary;
		border: 0;

		&:hover {
			color: $white;
		}
	}

	select {
		@extend .e-select;
		@extend .e-select--white;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		margin-bottom: $spacing-half;
		margin-left: 0;
		padding-left: 0;

		a {
			color: $white;
		}

		&:last-of-type {
			margin-bottom: 0;

			a {
				font-weight: $font-bold;
			}
		}
	}


	.woocommerce-pagination { margin-top: $spacing-base; }

	.woocommerce-Address {
		margin-top: $spacing-base;

		header {
			margin-bottom: $spacing-base;
		}

		h3 {
			margin-bottom: 0 !important;
		}

		@include by-device(ipad) {
			margin-top: 0;
		}

		&:first-of-type { margin-top: 0; }
	}

	h3 {
		@include heading(four);
		color: $colour-primary;
		margin-bottom: $spacing-2x;
	}

	mark {
		background-color: transparent;
		font-weight: $font-bold;
	}

	.edit-account {
		display: flex;
		flex-wrap: wrap;

		label {
			width: 100%;
			font-weight: $font-bold;
			color: $colour-primary;

			span {
				color: $error;
			}
		}

		.woocommerce-form-row {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			margin: 0 !important;

			span {
				display: inline-block;
				padding-top: $spacing-half;
				font-size: $font-size-small;
			}

			input { width: 100%; }
		}

		fieldset {
			border-color: $colour-primary;
			border-radius: $border-radius;
			margin-top: $spacing-2x;
			padding: $spacing-2x;

			legend {
				color: $colour-primary;
				padding-left: $spacing-base;
				padding-right: $spacing-base;
				font-weight: $font-black;
			}

			p {
				display: flex;
				flex-wrap: wrap;

				input, span {
					width: 100%;
				}
			}
	   }
	}

	.woocommerce-orders-table {
		&.shop_table {
			border: 0;
			color: $black;

			thead {
				display: none;

				@include by-device(ipad-pro) {
					display: table-header-group;
				}

				th {
					white-space: nowrap;
					background-color: $colour-primary;
					border-top: 2px solid $grey-light;
					color: $white;
					padding-left: 0;
					padding-top: $spacing-half;
					padding-bottom: $spacing-half;
				}
			}

			tbody {
				border-bottom: 2px solid $grey-light;

				.woocommerce-orders-table__cell-order-total { font-weight: $font-black; }

				tr {
					display: flex;
					flex-wrap: wrap;

					@include by-device(ipad-pro) {
						display: table-row;
					}
				}

				td {
					width: 100%;

					@include by-device(ipad-pro) {
						width: 20%;
					}

					background-color: $white;
					padding-top: $spacing-half;
					padding-bottom: $spacing-half;
					border-top: 2px solid $grey-light;
					padding-left: 0;
					padding-right: 0;
					text-align: center;
					color: $colour-primary;

					&:last-of-type {
						a {
							background-color: transparent;
							color: $colour-primary;
							font-size: $font-size-small;
							padding: 0;
							text-transform: capitalize;
						}
					}
				}

				tr {
					margin-top: $spacing-base;

					&:first-of-type { margin-top: 0; }

					@include by-device(ipad) {
						margin-top: 0;
					}
				}
			}
		}
	}

	.woocommerce-order-details {
		background-color: $white;
		border-radius: $border-radius;
		margin-bottom: 0;
		margin-top: $spacing-2x;
		color: $black;

		&__title { color: $colour-primary; }

		.woocommerce-table--order-details {
			margin-top: $spacing-base !important;
			border: 0;
			border-radius: 0;
			border-top: 2px solid $grey-light;
			border-bottom: 2px solid $grey-light;
			text-align: left;

			tfoot {

				th {
					border-top: 2px solid $grey-light;
					padding-left: $spacing-half;
				}

				td {
					border-top: 2px solid $grey-light;
					font-weight: $font-regular;
					padding-left: $spacing-half;
				}
			}

			thead {
				background-color: $colour-primary;
				color: $white;

				th {
					padding-left: $spacing-half;
				}
			}

			tbody {
				td {
					border-top: 2px solid $grey-light;
					background-color: $white;
					color: $black;
					padding-left: $spacing-half;

					.wc-item-meta {
						display: flex;
						flex-wrap: wrap;
						li {
							width: 100%;
							display: inline-flex;
							text-transform: uppercase;
							margin-bottom: 0;

							* { white-space: nowrap; }
							p {
								margin-top: 0;
								margin-left: $spacing-quarter;
							}
						}
					}
				}
			}

			th, td {
				padding-left: 0;
			}
		}
	}

	.woocommerce-customer-details {
		margin-bottom: 0;

		.woocommerce-column--billing-address,
		.woocommerce-column--shipping-address {
			border-radius: $border-radius;
			background-color: $white;
			margin-top: $spacing-2x;
			color: $black;

			h2 { color: $colour-primary; }

			address {
				padding: 0;
				border: 0;
			}
		}
	}

	.woocommerce-Addresses {
		margin-top: $spacing-2x;
		color: $black;
		display: flex;
		flex-wrap: wrap;

		.u-column1,
		.u-column2 {
			width: 100%;

			@include by-device(ipad-pro) {
				width: calc(50% - $spacing-2x);
			}
		}

		.u-column1 {
			margin-right: $spacing-2x;
		}

	}

	.woocommerce-address-fields {
		.form-row { padding: 0; }

		p {
			display: flex;
			flex-wrap: wrap;

			input, span {
				width: 100%;
			}
			label {
				font-weight: $font-bold;
				color: $colour-primary;
			}

			button {
				@extend .e-btn;
				@extend .e-btn--primary;
				height: 50px;
			}
		}
	}

	.woocommerce-message {
		padding: $spacing-half $spacing-base;
		background-color: $grey-light;
		display: flex;
		justify-content: space-between;
		align-items: center;

		a {
			color: $white !important;
			text-decoration: none !important;
			font-weight: $font-bold !important;
			font-size: $font-size-small !important;
			margin-top: 0 !important;
			order: 2;
		}
	}
}

.woocommerce-error { border-top: 3px solid $error; }
.woocommerce-message { border-top: 3px solid $colour-primary;}
