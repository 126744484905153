.woocommerce-checkout {

	#sagepayform-card-icons {
		display: flex;
		justify-content: center;
		margin-top: 10px;

		img {
			margin: 0 5px;
		}
	}

	.woocommerce-form-login {
		background-color: $white;

		label {
			font-weight: $font-bold;
			color: $colour-primary;
			text-transform: uppercase;
		}
	}


	.create-account {
		margin-top: $spacing-base;

		.woocommerce-input-wrapper {
			width: 100%;
		}

		.form-row {
			display: flex;
			flex-wrap: wrap;

			label { width: 100%; }

			input {
				margin-top: $spacing-quarter;
				width: 100%;
			}
		}
	}

    .woocommerce {
        position: relative;

        small {
            font-size: 8px;
        }

        #order_review_heading {
            display: none;
        }
    }

    //coupon toggle
    .woocommerce-form-coupon-toggle, .woocommerce-form-login-toggle {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .woocommerce-info {
            width: 100%;
            padding: $spacing-base;
            @extend .c-card;
            @extend .c-card--white;

            a {
                display: block;

                @include by-device(ipad) {
                    display: inline-block;
                }
            }
        }
    }

    .checkout_coupon {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        padding: $spacing-base;
	    padding-top: 0;
        margin-bottom: $spacing-base;
        @extend .c-card;
        @extend .c-card--white;

        p:first-child {
            display: none;
        }

        label {
            display: none;
            margin-bottom: $spacing-quarter;
        }

        input {
            background-color: $white;
            width: 100%;
        }

        .form-row-first {
            width: 100%;
            margin-bottom: 10px;

            @include by-device(ipad) {
                margin-bottom: 0;
                width: 50%;
            }
        }

        .form-row-last {
            width: 100%;

            @include by-device(ipad) {
                width: auto;
            }
        }

        button {
            @extend .e-btn;
            @extend .e-btn--primary;
            width: 100%;
	        border: 0;

            @include by-device(ipad) {
                margin-left: $spacing-half;
                width: auto;
            }
        }
    }

    .woocommerce-form-login {
        width: 100%;
        flex-direction: row;
        padding: $spacing-base;
        margin-bottom: $spacing-base;
        @extend .c-card;
        @extend .c-card--grey;

        label {
            display: block;
            width: 100%;
            margin-bottom: 5px;
        }

        input {
            background-color: $white;
        }

        button {
            @extend .e-btn;
            @extend .e-btn--primary;
        }

        p {
            margin-bottom: 10px;

            &:last-of-type {
                margin-top: 0;
            }
        }
    }





    // Global Layout
    .checkout {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
	    margin-top: $spacing-2x;

        @include by-device(ipad-pro) {
            margin-left: -40px;
        }

        @include by-device(laptop) {
            margin-left: -60px;
        }

        h3 {
            margin-bottom: $spacing-base;
            @extend .e-heading;
            @extend .e-heading--primary;
            @extend .e-heading--three;
            @extend .e-heading--four\@ipad;
        }
    }




    // Input and selects
    .woocommerce-billing-fields__field-wrapper, .woocommerce-shipping-fields__field-wrapper, .woocommerce-additional-fields__field-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$spacing-base;

        .form-row {
            padding-left: $spacing-base;
            width: 100%;
            max-width: 100%;
            margin-bottom: $spacing-base;

            @include by-device(ipad) {
                width: 50%;
                max-width: 50%;
            }

            &.notes {
                max-width: 100%;
                width: 100%;
            }

            label {
                display: block;
                margin-bottom: $spacing-quarter;
            }

            input,textarea {
                width: 100%;
            }

            textarea {
                height: 120px;
                resize: none;
            }

            select {
                @extend .e-select;
            }
        }
    }





    // Order Review and payments
    #order_review {
        width: 100%;
        top: 0;
        left: 0;
        position: sticky;
	    top: $spacing-2x;

	    table {
		    background-color: $white;
	    }

        @include by-device(ipad-pro) {
            width: 40%;
            max-width: 40%;
            padding-left: 40px;
        }

        @include by-device(laptop) {
            padding-left: 60px;
        }

        .woocommerce-checkout-payment {
            padding: $spacing-base;
            margin-top: $spacing-base;
            color: $white;
            @extend .c-card;
            @extend .c-card--primary;

            p {
                color: $white;
            }

            a {
                color: $white;
                font-weight: $font-bold;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
            }

            li {
                padding: 0;
                margin: 0;
                margin-top: $spacing-half;

                label {
                    font-weight: $font-bold;
                }

                &:first-of-type {
                    margin-top: 0;
                }
            }

            button {
                margin-top: $spacing-base;
                @extend .e-btn;
                @extend .e-btn--buy;
                @extend .e-btn--full;
	            border: 0;
            }


            .wc_payment_method {
                position: relative;
                margin-top: $spacing-base;

                &:first-of-type {
                    margin-top: 0;
                }

                input {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 50px;
                    opacity: 0;

                    &:checked ~ label {
                        opacity: 1;
                    }
                }

                label {
                    width: 100%;
                    opacity: 0.45;
                    margin-bottom: 5px;
                    @extend .e-btn;
                    @extend .e-btn--white;
                    color: $colour-primary;
                }

                .payment_box {
                    margin-top: $spacing-half;
                }
            }

            .woocommerce-privacy-policy-text {
                margin-top: $spacing-base;
            }
        }
    }




    // Main Customer Details
    #customer_details {
        width: 100%;
        margin-top: 20px;

        @include by-device(ipad-pro) {
            margin-top: 0;
            width: 60%;
            max-width: 60%;
            padding-left: 40px;
        }

        @include by-device(laptop) {
            padding-left: 60px;
        }

	    label {
		    font-weight: $font-bold;
		    color: $colour-primary;
	    }
    }





    // Review order table
    .shop_table {
        display: block;
        border-radius: $border-radius;
        border: 1px solid $colour-primary;
        overflow: hidden;

        tbody, thead, tfoot { display: block; }

        thead {
            background-color: $colour-primary;
            color: $white;
            padding: $spacing-half $spacing-base;
        }

        tbody, tfoot {
            padding: $spacing-base;
        }

        tfoot {
            padding-top: 0;
        }

        tr {
            display: flex;
            justify-content: space-between;
        }

        .shipping {
            small {
                display: none;
            }
        }

        .woocommerce-shipping-methods {
            margin: $spacing-half 0;
            input {
                margin-right: $spacing-half;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                margin: 0;
                padding: 0;
                text-align: right;
                position: relative;
                width: 100%;
                margin-top: 5px;

                &:first-of-type {
                    margin-top: 0;
                }

            }
        }

        .woocommerce-remove-coupon {
            color: $colour-primary;
            font-size: $font-size-small;

            &:hover {
                color: darken($colour-primary, 10);
            }
        }
    }





    // Shipping
    .woocommerce-shipping-totals {
	    padding-top: $spacing-half;
	    padding-bottom: $spacing-half;
        margin: $spacing-half 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        @extend .c-card;
        @extend .c-card--white;
	    border-top: 1px solid $grey;
	    border-bottom: 1px solid $grey;

        td {
            width: 100%;
        }

        ul {
            li {
                text-align: left;
                margin-top: 10px;

                &:first-of-type {
                    margin-top: 0;
                }

                .woocommerce-Price-amount {
                    font-weight: $font-bold;
                }

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 50px;
                    opacity: 0;

                    &:checked ~ label {
                        opacity: 1;
                    }
                }

                label {
                    @extend .e-btn;
                    @extend .e-btn--full;
					@extend .e-btn--primary;
	                opacity: 0.45;
	                cursor: pointer;
                }
            }
        }
    }





    // Ship to diffirent address
    #ship-to-different-address {
        position: relative;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:checked ~ span {
                opacity: 0.35;
            }
        }

        span {
            @extend .e-btn;
            @extend .e-btn--primary;
            @include transition(opacity, color, background);
        }
    }
}
