/* ============================================================
	* Components - Accordion
============================================================ */

/**
 * A simple accordion that uses vanilla JS
 * to show and hide content.
 *
 */

.c-accordion {

	.c-accordion__trigger {
		cursor: pointer;

		&.active {
			.c-accordion__icon {
				transform: rotate(180deg);
			}
		}
	}

	.c-accordion__icon {
		font-size: 26px;
		@include transition(transform);

		@include by-device(ipad) {
			font-size: 30px;
		}
	}


	.c-accordion__content {
		position: relative;
		height: 0px;
		overflow: hidden;
		@include transition(height);
	}

}
