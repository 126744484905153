.e-btn {
	@include transition(background-color, color);
	display: inline-block;
	font-size: $button-font-size;
    font-weight: $button-weight;
    line-height: $line-height-small;
    padding: $button-spacing;
	border-radius: $border-radius;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	width: 100%;

	@include by-device(ipad) {
		width: auto;
	}

	&--white {
	    background-color: $white;

	    &:hover {
	        background-color: darken($white, 2%);
	    }
	}

	&--primary {
		background-color: $colour-primary;
		color: $white;

		&:hover {
			background-color: lighten($colour-primary, 10%);
		}
	}

	&--full {
		width: 100%;
		min-width: 100%;
	}

	&--buy {
		background-color: $success;
		color: $white;

		&:hover {
			background-color: lighten($success, 10%);
		}
	}
}


