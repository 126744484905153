/** ========================================
 * Link Style
 ** ===================================== **/

.e-link {
	@include transition(color);
    text-decoration: underline;

    &--black { color: $black; }
    &--white { color: $white; }
    &--primary { color: $colour-primary; }
	&--quaternary { color: $colour-quaternary; }

	&--plain { text-decoration: none; }
	&--large { font-size: 18px; }
	&--bold { font-weight: $font-bold; }
}
