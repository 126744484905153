/* ==========================================================================
   * Settings - Globals
========================================================================== */

/**
 *  The global settings that are too small for there own
 *  file, here we can set the transitions, box-shadows
 *  and the border radius that we use in the project.
 *
 */


/* Heigts
============================================================ */

$heights: 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;





/* Transitions
============================================================ */

$transition-time: 300ms;
$transition-ease: ease-in-out;





/* Border Radius
============================================================ */

$border-radius: 0px;
$border-radius-half: calc($border-radius / 2);
$border-radius-2x: $border-radius * 2;
$border-radius-3x: $border-radius * 3;





/* Box Shadow
============================================================ */

$box-shadow: 0 5px 10px rgba($black, 0.1);
$box-shadow-large: 0 15px 15px rgba($black, 0.15);
$box-shadow-small: 0 2.5px 5px rgba($black, 0.05);
