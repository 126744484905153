
/* ============================================================
	* Tools - Media Query
============================================================ */

/**
 * Media-queries are really simple they are set
 * up in the global settings and use device names
 * which are the easiest way to remember them.
 * Out of the box, this framework is mobile
 * first but there is media queries generated for both min
 * and max widths,
 * Example:
 * @include by-device(ipad) {}
 * @include by-device(ipad--max) {}
 *
 */

@mixin by-device($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: #{map-get($breakpoints, $breakpoint)}) {
			@content;
		}
	}

	@else if map-has-key($breakpoints, str-slice($breakpoint, 1, -6)) {
		@media (max-width: #{map-get($breakpoints, str-slice($breakpoint, 1, -6)) - 1}) {
			@content;
		}
	}

	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		+ "Please make sure it is defined in `$breakpoints` map.";
	}

}
