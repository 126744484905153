/* ============================================================
	* Elements - Form / Ninja Styles
============================================================ */

/**
 * Ninja form styles
 *
 */


.e-form {

	&--newsletter {

		.submit-container { margin-top: 0 !important; }
		.nf-form-cont .nf-field-container { margin-bottom: 0 !important; }

		nf-cells {
			.nf-cell:nth-child(2) { padding-left: 0 !important; }
		}

		.html-wrap { margin-top: $spacing-base !important; }
	}

	&--product-archive {
		flex-wrap: wrap;

		.woocommerce-ordering {
			width: 100%;
			margin-top: $spacing-half;

			@include by-device(ipad) {
				width: auto;
				margin-top: 0;
			}
		}

		select {
			@extend .e-select;
			width: 100%;
		}
	}
}


.nf-form-cont {
	width: 100%;

	select {
		@extend .e-select;
		width: 100%;
	}

	.ninja-forms-req-symbol {
		color: $error;
	}


	.nf-form-fields-required {
		display: none;
	}


	.ninja-forms-req-symbol {
		display: none;
	}


	.nf-field {
		margin-top: 0;
	}


	nf-cells {
		@include by-device(ipad) {
			display: flex;
			align-items: center;
			margin-left: -40px;
		}


		.nf-cell {
			padding: 0;

			@include by-device(ipad) {
				padding-left: 40px;
			}
		}

	}


	.nf-field-container.submit-container {
		margin-bottom: 0;
		margin-top: 10px;

		.nf-field-element {
			position: relative;
		}

	}


	.nf-field-container {
		margin-bottom: 18px;
	}


	.nf-form-content {
		margin: 0;
		padding: 0;

		.checkbox-wrap {
			label::before {
				content: '';
				width: 10px;
				height: 10px;
				left: -24px;
				top: 4px;
				background-color: $colour-primary;
				@include transition(opacity);
			}

			.nf-checked-label:after {
				background-color: $black;
			}


			label::after {
				border: 0;
				background-color: rgba($black, 0.2);
				@include transition(background);
			}
		}


		.nf-field-label {
			margin-bottom: 0;

			label {
				font-weight: $font-bold;
				font-size: $font-size-body;
				color: $colour-primary;
			}

		}


		.nf-field-element {
			margin-top: 0;
			position: relative;

			&::after {
				font-family: 'Material Icons';
				font-feature-settings: 'liga';
				opacity: 0;
				position: absolute;
				right: $spacing-base;
				top: 10px;
				font-size: 20px;
				@include transition(opacity, colour);
			}

			input[type="text"],
			input[type="tel"],
			input[type="email"],
			textarea {
				width: 100%;
				background-color: $white;
			}


			textarea {
				height: 150px;
				resize: none;
			}


			input[type="button"],
			input[type="submit"] {
				@extend .e-btn;
				@extend .e-btn--primary;
				outline: 0;
				border: 0;
				height: 50px;
			}


			&.nf-pass {
				input[type="text"],
				input[type="tel"],
				input[type="email"],
				textarea {
					border-color: $success;
				}

			}

		}

		.label-hidden {
			.nf-field-label {
				display: none;
			}
		}


		.field-wrap {
			&.nf-pass {
				.nf-field-element {
					&::after {
						content: 'task_alt';
						color: $success;
						opacity: 1;
					}
				}

				input[type="text"],
				input[type="tel"],
				input[type="email"],
				textarea {
					border-color: $success;
				}
			}

			&.nf-error {
				.nf-field-element {
					&::after {
						content: 'highlight_off';
						color: $error;
						opacity: 1;
					}
				}

				input[type="text"],
				input[type="tel"],
				input[type="email"],
				textarea {
					border-color: $error;
				}
			}

			.nf-error-msg {
				display: none;
			}
		}


		.nf-error {
			.nf-error-msg {
				display: none;
			}
		}

	}

}

.nf-response-msg {

	p {
		background-color: $white;
		color: $black;
		display: inline-block;
		padding: $spacing-half $spacing-base;
		margin: $spacing-base 0;
		font-size: $font-size-small;
		font-weight: $font-bold;
		border-radius: 0;
	}

}


.nf-form-hp {
	position: absolute;
	visibility: hidden;
}


#nf-form-errors-1 {
	margin-top: 0;

	.nf-error-msg {
		margin-top: $spacing-base;
		background-color: $error;
		color: $white;
		display: inline-block;
		padding: $spacing-quarter $spacing-half;
		border-radius: 0;
		font-size: $font-size-small;
		font-weight: $font-regular;
	}

}
