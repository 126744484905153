.c-header {

	&__inner {
		min-height: 112px;
	}

	&__logo {
		width: 200px;
		min-height: 72px;
		max-height: 72px;
	}

	&__search {

		input {
			width: 100%;

			@include by-device(ipad-pro) {
				max-width: 365px;
			}

			@include by-device(laptop) {
				max-width: 345px;
				min-width: 345px;
			}
		}

		> span {
			position: absolute;
			right: $spacing-base;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__secondary-trigger {
		cursor: pointer;
		min-height: 55px;

		&.active {
			.material-icons:nth-child(1) { display: none; }
			.material-icons:nth-child(2) { display: flex; }
		}
	}

	&__usps {
		height: 46px;
		max-height: 46px;
		overflow: hidden;
	}

	&__qty {
		width: 26px;
		height: 26px;
		background-color: $grey;
		border-radius: 100px;
	}
}
