#customer_login {
	display: flex;
	flex-wrap: wrap;

	.u-column1,
	.u-column2 {
		width: 100%;

		@include by-device(ipad-pro) {
			width: calc(50% - $spacing-2x);
		}
	}

	.u-column1 {
		margin-bottom: $spacing-2x;

		@include by-device(ipad-pro) {
			margin-bottom: 0;
			margin-right: $spacing-2x;
		}
	}

	h2 {
		@extend .e-heading;
		@extend .e-heading--two\@laptop;
		@extend .e-heading--three\@ipad;
		@extend .e-heading--four;
		@extend .e-heading--primary;
		margin-bottom: $spacing-base;
	}

	.woocommerce-form-login,
	.woocommerce-form-register {
		@extend .c-card;
		@extend .c-card--white;
		padding: $spacing-base;
		border: 1px solid $grey-light;

		@include by-device(ipad-pro) {
			padding: $spacing-2x;
		}

		p {
			display: flex;
			flex-wrap: wrap;
			margin-top: $spacing-base;

			&:first-of-type {
				margin-top: 0;
			}

			label {
				font-weight: $font-bold;
				color: $colour-primary;
				margin-bottom: $spacing-quarter;
			}


			input, span {
				&:not(input[type="checkbox"]) {
					width: 100%;
				}
			}

			button {
				@extend .e-btn;
				@extend .e-btn--primary;
				border: 0;
				width: 100%;
				margin-top: $spacing-base;
			}
		}
	}

	.woocommerce-privacy-policy-text {
		margin-top: $spacing-base;

		p {
			display: inline-block;
		}
	}

	.woocommerce-password-strength {
		display: none;
	}

	.woocommerce-form-register p button {
		margin-top: 0;
	}
}
