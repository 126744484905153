.c-woocommerce {
	&::before {
		position: absolute;
		content: '';
		background: linear-gradient(to bottom, $colour-secondary, $white);
		width: 100%;
		height: 50%;
		top: 0;
		left: 0;
	}
}
