.e-pagination {
	&__item {
		@include transition(background-color);
		position: relative;
		display: flex;
		width: 50px;
		height: 50px;
		border: 1px solid darken($colour-tertiary, 10%);

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		a, span {
			@include transition(color);
		}

		&.e-pagination--current {
			background-color: darken($colour-secondary, 20%);

			a, span { color: $white !important; }
		}

		&:hover {
			background-color: darken($colour-tertiary, 5%);

			a, span { color: $white !important; }
		}
	}

	&--wordpress {
		display: flex;
		align-items: center;

		.page-numbers {
			margin-left: $spacing-half;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.page-numbers {
		@include transition(background-color, color);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background-color: $colour-tertiary;
		color: $colour-primary;

		&.current {
			color: $white;
			background-color: $colour-primary;

			&:hover {
				background-color: $colour-primary;
			}
		}

		&:hover { background-color: $colour-secondary; }
	}
}

