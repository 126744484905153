/* ==========================================================================
   * Settings - Typography
   ========================================================================== */

/**
 * The global Typography settings for the project including
 * the font family, sizes, weights and letter spacing.
 *
 */

/* Typography Family
============================================================ */

$font-family-primary: 'Inter', sans-serif;
$font-family-secondary: $font-family-primary;





/* Typography Weights
============================================================ */

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi: 600;
$font-bold: 700;
$font-black: 900;





/* Typography Heading Sizes
============================================================ */

$headings: (
   	one: (
		font-size: 40px,
		line-height: 50px,
		letter-spacing: 0
   	),
   	two: (
		font-size: 36px,
		line-height: 46px,
		letter-spacing: 0
   	),
   	three: (
		font-size: 30px,
		line-height: 40px,
		letter-spacing: 0
   	),
	four: (
		font-size: 28px,
		line-height: 38px,
		letter-spacing: 0
   	),
	five: (
		font-size: 20px,
		line-height: 30px,
		letter-spacing: 0
   	),
	six: (
		font-size: 16px,
		line-height: 26px,
		letter-spacing: 0
   	)
);





/* Typography Body Sizes
============================================================ */

$font-size-body: 16px;
$font-size-small: 14px;





/* Typography Body Line Heights
============================================================ */

$line-height-body: 26px;
$line-height-small: 16px;





/* Typography Settings
============================================================ */

/**
 * 	1. Enable this option to use the global
 *  font line height. If disabled each
 *  heading shall use its own line height
 *  value set in this file.
 *
 * 	2. Enable mobile sizes to auto size
 *	the headings for mobile. I pefer to
 *	manually set these on a case by
 * 	case heading to have total control.
 */

$font-line-height: 10px;
$heading-weight: $font-bold;
$automatic-line-heights: false; /* [1] */
$automatic-mobile-sizes: false; /* [2] */





/* Typography ( Update from Settings )
============================================================ */

@if ($automatic-line-heights) {
   $line-height-body: $font-size-body + $font-line-height;
}
