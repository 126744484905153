.c-vat-switch {

    &__trigger {
        width: 40px;
        height: 20px;
        border-radius: 10px;
        position: relative;
        cursor: e-resize;
        background-color: transparent;
	    border: 1px solid $colour-primary;

        &:before {
            @include transition(left, width, height);
            content: '';
            height: 20px;
            width: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: $colour-primary;
            border-radius: 10px;
        }

    }

	p { font-size: $font-size-small; }

    &.active {

        .c-vat-switch__trigger {
            cursor: w-resize;

            &:before { left: 20px; }
        }

    }
}


.wcvat-toggle-widget {
    width: 40px !important;
    height: 20px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    background-color: $white !important;
    border: 1px solid $colour-primary;

    span {
        display: none;
    }

    &:before {
        @include transition(left, width, height);
        content: '';
        height: 20px;
        width: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) translateX(-1px);
        background-color: $colour-primary;
        border-radius: 10px;
        background-image: none !important;
    }

    &.on {
        &:before { left: 20px; }

        background-color: lighten($colour-primary, 60%) !important;
    }
}