/* ============================================================
	* Utilities - Helpers
============================================================ */

/**
 * The helpers file is a simple place to add commenly used
 * helpers to maintain a DRY code app.
 *
 */

/** Positions
============================================================ */

.u-pos-rel { position: relative; }
.u-pos-sticky { position: sticky; top: $spacing-base }





/** No click events
============================================================ */

.u-no-clicky {
	pointer-events: none;

	@each $device, $breakpoint in $breakpoints {
		@media (min-width: $breakpoint) {
			.u-no-clicky\@#{$device} { pointer-events: none; }
		}
	}
}





/** Text Alignment
============================================================ */

.u-tac { text-align: center; }
.u-tar { text-align: right; }
.u-tal { text-align: left; }

@each $device, $breakpoint in $breakpoints {
	@media (min-width: $breakpoint) {
		.u-tac\@#{$device} { text-align: center; }
		.u-tar\@#{$device} { text-align: right; }
		.u-tal\@#{$device} { text-align: left; }
	}
}





/** Text Uppercase
*
* A helper to make text uppercase.
============================================================ */

.u-uppercase { text-transform: uppercase; }





/** Max Widths
*
* Basic max width options
============================================================ */

.u-no-max { max-width: 100%; }





/** Font Colour
*
* Colouring text that isn't a heading
============================================================= */
.u-font-colour-white { color: $white; }
.u-font-colour-black { color: $black; }
.u-font-colour-primary { color: $colour-primary; }
.u-font-colour-secondary { color: $colour-secondary; }
.u-font-colour-tertiary { color: $colour-tertiary; }
.u-font-colour-quaternary { color: $colour-quaternary; }
.u-font-colour-grey { color: rgba($black, 0.6); }





/** Font Weights
*
* Setting font options by class
============================================================= */

.u-font-light { font-weight: $font-light; }
.u-font-regular { font-weight: $font-regular; }
.u-font-medium { font-weight: $font-medium; }
.u-font-semi { font-weight: $font-semi; }
.u-font-bold { font-weight: $font-bold; }





/** Font Size
*
* Setting font size by class
============================================================= */

.u-font-small {
	font-size: $font-size-small;
	line-height: $line-height-small;
}





/** Darken
*
* Darken as an overlay
============================================================= */

.u-darken {
	@include transition(opacity);
	@include cover();
	background-color: $darken-colour;
	opacity: $darken-opacity;

	&--less {
		 opacity: math.div($darken-opacity, 1.5);
	}
}





/** Content Widths
*
* Stuff that needs no explaining
============================================================= */

.u-content-width {
	max-width: 100%;

	@include by-device(ipad-pro) {
		width: 70%;
	}
}





/** Generic
*
* Stuff that needs no explaining
============================================================= */

.u-above {
	position: relative;
	z-index: 99;
}





/** Cover
*
* Helper to cover a parent.
============================================================ */

.u-cover { @include cover(); }





/** No overflow
*
* Hide all overflow
============================================================ */

.u-no-overflow { overflow: hidden; }





/** Height 100%
============================================================ */

.u-h-100 { height: 100%; }





/** Margin Padding Tools
*
* Helper to remove margin / padding from first or last of
* type, helpful with menus or lists
============================================================ */

.u-remove-first {
	&:first-of-type {
		margin-left: 0;
		padding-left: 0;
		margin-top: 0;
	}
}


.u-remove-last {
	&:last-of-type {
		margin-right: 0;
		padding-right: 0;
		margin-bottom: 0;
	}
}





/* No Scroll
============================================================ */
.u-no-scroll {
	overflow-y: hidden;
}




/* Sticky
============================================================ */
.u-sticky {
	position: sticky;
	top: $spacing-2x;
	z-index: 99;
}



.u-border-bottom {
	border-bottom: 1px solid $colour-tertiary;

	&--grey {
		border-bottom: 1px solid $grey;
	}
}

.u-menu-darken {
	@include transition(opacity);
	@include cover();
	position: fixed;
	top: unset;
	bottom: 0;
	background-color: rgba($black, 0.4);
	opacity: 0;

	&.active {
		opacity: 1;
	}
}

.u-aspect-266-100 {
	@include by-device(ipad-pro) {
		@include aspect-ratio(266, 100);
	}
}

.u-cls-aspect {
	aspect-ratio: 266 / 100;
}


.u-z-up { z-index: 99; }


.u-linear-gradient {
	position: absolute;
	z-index: 1;

	&--right {
		right: 0;
		width: 70%;

		&.u-linear-gradient--secondary {
			background: linear-gradient(135deg, $white 0%, $colour-secondary 100%);
		}

		&.u-linear-gradient--quaternary {
			background: linear-gradient(135deg, $white 0%, rgba($colour-quaternary, 0.1) 100%);
		}
	}

	&--left {
		left: 0;

		&.u-linear-gradient--secondary {
			width: 70%;
			background: linear-gradient(135deg, $colour-secondary 0%, $white 100%);
		}
	}

	&--bottom {
		height: calc(100% - $spacing-2x);
		bottom: 0;
	}

	&--top {
		height: calc(100% - $spacing-2x);
		top: 0;
	}

	&--middle {
		left: 50%;
		transform: translateX(-50%);
		width: 100%;

		@include by-device(laptop) {
			width: 70%;
		}

		@include by-device(desktop) {
			width: 60%;
			max-width: 1340px;
		}

		&.u-linear-gradient--quaternary {
			background: linear-gradient(0deg, $white 0%, rgba($colour-quaternary, 0.1) 100%);
		}

		&.u-linear-gradient--top-more {
			height: calc(100% - $spacing-2x);
			top: unset;
			bottom: 0;
		}
	}
}



.u-fixed,
.u-percentage {
	@extend .e-btn;

	border: 2px solid $colour-primary !important;
	color: $colour-primary !important;

}

#percentage:checked:checked ~ .u-percentage,
#fixed:checked:checked ~ .u-fixed {
	background-color: $colour-primary !important;
	color: $white !important;
}

.u-no-whitespace {
	white-space: nowrap;
}

.u-icon-cls {
	width: 24px;
	height: 24px;
	overflow: hidden;
}

.u-pointer {
	cursor: pointer;
}
