.e-slider-arrow {
	@include transition(background-color);
	background-color: $colour-secondary;
	width: $spacing-2x;
	height: $spacing-2x;
	position: relative;
	border: 1px solid $colour-tertiary;

	> div {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 99;
	}

	&:hover {
		background-color: darken($colour-secondary, 5%);
	}
}
