.c-card {

	&--gradient {
		&.c-card--quaternary { background: linear-gradient(270deg, $white, $colour-secondary); }
	}

	&__marque {
		position: absolute;
		right: 0;
		top: -100px;
		width: 70%;
	}

	&--faq { border: 1px solid $grey; }

	&--white { background: $white; }
	&--secondary { background: $colour-secondary; }
	&--primary { background: $colour-primary; }
	&--grey { background: $grey-light; }
}
