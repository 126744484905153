/* ============================================================
	* Components - Single Product
============================================================ */

/**
 * Styling for single product page in WooCommerce
 *
 */

.c-single-product {
	//.woocommerce-variation.single_variation {
	//	position: absolute;
	//	left: 0;
	//	top: 0;
	//	pointer-events: none;
	//	opacity: 0;
	//	z-index: 1;
	//}

	.c-single-product__price {
		display: flex;
		font-size: 16px;
		align-items: flex-end;
		column-gap: 5px;

		.woocommerce-price-suffix {
			display: none;
		}

		.c-single-product__suffix {
			font-size: 12px;
		}

		del {
			width: 100%;
			text-decoration: none;
			height: 35px;

			.amount {
				font-size: 20px;
				color: $error;
				font-weight: $font-bold;
				text-decoration: none;

				&:after {
					content: "ex. VAT";
					font-size: 10px;
					text-decoration: none;
					font-weight: $font-regular;
					color: $black;
					margin-left: $spacing-quarter;
				}

				bdi {
					color: $error;
					margin-left: 0;
					font-size: 20px;
					font-weight: $font-bold;
					text-decoration: line-through;
				}
			}
		}

		ins {
			// width: 100%;
			text-decoration: none;

			&.hide-from {
				&::before {
					display: none;
				}
			}
		}

		.amount {
			color: $colour-primary;
			font-weight: $font-bold;
			font-size: 20px;

			bdi {
				@extend .e-heading;
				@extend .e-heading--primary;

				@include by-device(ipad) {
					@include heading(two);
				}

				@include by-device(laptop) {
					@include heading(one);
				}
			}
		}

		&.ex-vat {
			.c-single-product__suffix {
				margin-bottom: 2px;
			}
		}

		&.inc-vat {
			del {
				display: none;
			}

			.amount {
				bdi {
					font-size: 20px;
					line-height: 26px;
				}
			}

			.c-single-product__suffix {
				line-height: 10px;
				font-size: 10px !important;
				margin-bottom: 5px;
			}
		}
	}

	.price {
		@include transition(opacity, max-height);

		overflow: hidden;
		max-height: 100px;
		&.active {
			opacity: 0;
			max-height: 0;
		}
	}

	&__main {
		&::before {
			position: absolute;
			content: "";
			background: linear-gradient(to bottom, $colour-secondary, $white);
			width: 100%;
			height: 50%;
			top: 0;
			left: 0;
		}
	}

	&__stock-indicator {
		width: 15px;
		height: 15px;
		border-radius: 100px;
		background-color: $error;

		&--instock {
			background-color: $success;
		}
	}

	&__brand {
		width: 100px;
		mix-blend-mode: multiply;

		@include by-device(laptop) {
			width: 150px;
		}
	}

	&__flag {
		position: absolute;
		top: -18px;
		left: 0;
		z-index: 101;
		padding: 5px 20px;
		font-weight: $font-bold;
		text-transform: uppercase;
		font-size: $font-size-small;

		&--new {
			background-color: $success;
			color: $white;
		}

		&--sale {
			background-color: $error;
			color: $white;
		}
	}

	.product_title {
		@include heading(five);
		font-weight: $font-regular;
		margin-bottom: 20px;

		@include by-device(ipad) {
			@include heading(four);
		}

		@include by-device(laptop) {
			@include heading(three);
			max-width: 85%;
		}
	}

	.onsale {
		display: none;
	}

	.woocommerce-product-details__short-description {
		@extend .c-editable;
		margin-top: $spacing-base;
	}

	.woocommerce-product-gallery__image {
		border-radius: $border-radius;
		cursor: zoom-in;
	}

	.woocommerce-product-gallery__wrapper {
		margin: 0;
		padding: 0;

		img {
			width: 100%;
		}
	}

	.woocommerce-variation-description {
		margin-top: $spacing-base;
		@extend .c-editable;
	}

	.woocommerce-variation-price {
		margin-top: $spacing-base;
	}

	.variations {
		width: 100%;
		margin-top: $spacing-base;

		label {
			color: $colour-primary;
		}

		th.label {
			display: flex;
		}

		tr {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-top: $spacing-half;

			&:first-child {
				margin-top: 0;
			}

			label {
				font-weight: $font-semi;
				line-height: 16px;
			}

			.value {
				width: 100%;
			}

			select {
				@extend .e-select;
				@extend .e-select--white;
				box-shadow: $box-shadow-small;
				margin-top: $spacing-half;
				min-width: 300px;
				width: 100%;
				padding: $spacing-half $spacing-base;
			}
		}

		.reset_variations {
			display: none !important;
		}
	}

	.quantity {
		margin-top: $spacing-half;
		display: flex;
		flex-direction: column;

		input {
			background-color: $white !important;
			padding: $spacing-half $spacing-base;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&::before {
			content: "Quantity";
			font-size: 16px;
			line-height: 24px;
			font-weight: $font-bold;
			color: $colour-primary;
			margin-bottom: $spacing-half;
			display: block;
		}
	}

	.single_add_to_cart_button {
		@extend .e-btn;
		@extend .e-btn--buy;
		@extend .e-btn--full;
		border-radius: $border-radius;
		outline: 0 !important;
		border: 0 !important;
		margin-top: 20px;
	}

	.price {
		font-size: 16px;
		display: flex;
		flex-wrap: wrap;

		.woocommerce-variation-price {
			margin-top: 0 !important;
		}

		del {
			width: 100%;
			text-decoration: none;

			.amount {
				font-size: 20px;
				// text-decoration: line-through;
				color: $error;
				font-weight: $font-bold;

				.woocommerce-Price-amount {
					color: $error;
					margin-left: 0;
					font-size: 20px;
					font-weight: $font-bold;
				}
			}
		}

		ins {
			// width: 100%;
			text-decoration: none;

			&.hide-from {
				&::before {
					display: none;
				}
			}
		}

		.amount {
			color: $colour-primary;
			font-weight: $font-bold;
			font-size: 20px;

			.woocommerce-Price-amount {
				@extend .e-heading;
				@extend .e-heading--primary;

				@include by-device(ipad) {
					@include heading(two);
				}

				@include by-device(laptop) {
					@include heading(one);
				}
			}
		}
	}

	.woocommerce-product-gallery {
		width: 100% !important;
		float: none !important;
		position: relative;

		.woocommerce-product-gallery__image {
			overflow: hidden;

			a {
				display: flex;
				width: 100%;
				max-width: 100%;
				min-width: 100%;
				border: 0;
				border-radius: 5px;
				background-color: $white;
				cursor: pointer;
				@include aspect-ratio(1, 1);

				img {
					object-fit: contain;
					opacity: 1 !important;
					@include transition(opacity);
					@include cover();
				}
			}

			.zoomImg {
				z-index: 99 !important;
			}
		}
	}

	.flex-viewport {
		overflow: hidden;
		border-radius: 0;
		height: auto !important;
	}

	.flex-direction-nav {
		position: relative;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		li {
			margin: 0;
			padding: 0;
			width: 50px;
			height: 50px;
			background-color: $colour-secondary;
			position: relative;
			border-radius: $border-radius;

			&::after {
				font-family: "Material Icons";
				font-feature-settings: "liga";
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100%;
				color: $white;
				font-size: 36px;
				text-align: center;
			}

			a {
				@include cover(2);
				opacity: 0;
			}
		}

		.flex-nav-prev {
			left: 0;

			&::after {
				content: "chevron_left";
			}
		}

		.flex-nav-next {
			right: 0;

			&::after {
				content: "chevron_right";
			}
		}
	}

	.flex-control-nav {
		@extend .o-list-bare;
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		align-items: center;
		overflow: hidden;
		width: calc(100% + 10px);
		margin-left: -10px !important;
		margin-right: 0;
		padding: 0;

		li {
			margin-top: $spacing-base;
			width: calc(25% - 10px);
			max-width: calc(25% - 10px);
			min-width: calc(25% - 10px);
			@extend .o-list-bare__item;
			border: 0;
			border-radius: 5px;
			overflow: hidden;
			background-color: $white;
			padding: 0px;
			margin-left: 10px !important;
			cursor: pointer;
			@include aspect-ratio(1, 1);

			@include by-device(ipad-pro) {
				width: calc(20% - 10px);
				max-width: calc(20% - 10px);
				min-width: calc(20% - 10px);
			}

			img {
				object-fit: contain;
				opacity: 1 !important;
				@include transition(opacity);
				@include cover();

				&:hover {
					opacity: 1 !important;
				}

				&.flex-active {
					opacity: 0.5 !important;
				}
			}
		}
	}

	.screen-reader-text {
		display: none;
	}

	&--sold-individually {
		.quantity {
			&::before {
				display: none;
			}
		}
	}
}
