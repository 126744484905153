.c-product {
	@include transition(border-color);
	border: 1px solid $grey;

	&:hover {
		border-color: darken($grey, 10%);
	}

	&--sale {
		border-color: $error;

		&:hover {
			border-color: darken($error, 10%);
		}
	}

	&--new {
		border-color: $success;

		&:hover {
			border-color: darken($success, 10%);
		}
	}

	&__flag {
		position: absolute;
		bottom: calc(100% - 18px);
		left: -1px;
		padding: 5px 10px;
		color: $white;
		font-size: $font-size-small;
		font-weight: $font-bold;
		text-transform: uppercase;
		z-index: 99;

		&--sale {
			background-color: $error;
		}
		&--new {
			background-color: $success;
		}
	}

	&__price {
		del {
			color: red;
			width: 100%;

			.amount {
				color: red;
				font-size: $font-size-small;

				bdi {
					font-size: $font-size-small;
				}
			}
		}

		ins {
			color: $colour-primary;
			text-decoration: none;
		}

		.amount {
			font-size: $font-size-small;
			color: $colour-primary;

			bdi {
				font-size: 18px;
				font-weight: $font-bold;

				@include by-device(ipad) {
					font-size: 24px;
				}
			}
		}

		.woocommerce-price-suffix {
			display: none;
		}
	}
}
