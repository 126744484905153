.c-comparison {
	border: 1px solid $grey-light;

	&--recommended { border-color: $colour-quaternary; }

	&__flag {
		position: absolute;
		bottom: calc(100% - 18px);
		left: -1px;
		padding: 5px 10px;
		color: $white;
		font-size: $font-size-small;
		font-weight: $font-bold;
		text-transform: uppercase;
		z-index: 99;
		background-color: $colour-quaternary;
	}
}
