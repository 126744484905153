.c-product-archive {

	.e-form--product-archive > div {
		display: none !important;
	}

	&__header {
		&::before {
			position: absolute;
			content: '';
			background: linear-gradient(to bottom, $colour-secondary, $white);
			width: 100%;
			height: 50%;
			top: 0;
			left: 0;
		}
	}

	.woocommerce-result-count {
		color: lighten($black, 60%) !important;
	}

	&__body {
		&::before {
			position: absolute;
			content: '';
			background: linear-gradient(to bottom, $white, $colour-secondary);
			width: 100%;
			height: 50%;
			bottom: 0;
			left: 0;
		}
	}

	&__filters {

		h4 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			color: $colour-primary;
			@extend .e-heading;
			@extend .e-heading--five;
		}

		.woof_submit_search_form_container{
			display: none;
		}

		.woof_front_toggle.woof_front_toggle_opened::after {
			transform: translateY(-50%) rotate(180deg);
		}

		.woof_front_toggle::after {
			content: 'expand_more';
			font-family: 'Material Icons';
			font-feature-settings: 'liga';
			display: block;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			@include transition(transform);
		}


		.woof_container_onsales .woof_container_inner {
			label {
				padding: 0;
				margin: 0;
				margin-left: 30px;
				font-size: 14px;
				line-height: 40px;
				font-weight: 400;

				&.woof_radio_label_selected, .woof_checkbox_label_selected {
					color: $colour-secondary;
				}
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 25px;
				height: 25px;
				display: block;
				background-color: $grey;
				border-radius: 0;
			}

			label::after {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				z-index: 2;
				width: 25px;
				height: 25px;
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: 0;
				background-color: $colour-primary;
				@include transition(opacity);
			}

			input[type="checkbox"], input[type="radio"] {
				position: absolute;
				width: 20px;
				height: 20px;
				z-index: 3;
				opacity: 0;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			input[type="checkbox"]:checked + label::after { opacity: 1;}
			input[type="radio"]:checked + label::after { opacity: 1; }
		}

		.woof_container {
			border-bottom: 1px solid $grey;
			padding: 20px 0;
			margin-bottom: 0;

			&:first-of-type {
				border-top: 1px solid $grey !important;
			}
		}

		.woof_list, .woof_authors {
			padding-left: 0;
			margin: 0;
			padding-top: 10px;
			display: block;

			.woof_radio_term_reset {
				display: none !important;
			}


			li {
				padding: 0;
				margin: 0;
				display: flex;
				align-items: center;
				position: relative;
				padding-bottom: 0 !important;
				padding-left: 30px !important;

				span {
					display: none;

					&.amount {
						display: inline-block;

						bdi {
							display: flex;
						}
					}

					&.woocommerce-Price-currencySymbol {
						display: block;
					}
				}

				label {
					padding: 0;
					margin: 0;
					margin-left: 10px;
					font-size: 14px;
					line-height: 40px;
					font-weight: 400;

					&.woof_radio_label_selected, .woof_checkbox_label_selected {
						color: $colour-secondary;
					}
				}

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 25px;
					height: 25px;
					display: block;
					background-color: $grey;
					border-radius: 0;
				}

				label::after {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					z-index: 2;
					width: 25px;
					height: 25px;
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0;
					background-color: $colour-primary;
					@include transition(opacity);
				}

				input[type="checkbox"], input[type="radio"] {
					position: absolute;
					width: 20px;
					height: 20px;
					z-index: 3;
					opacity: 0;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}

				input[type="checkbox"]:checked + label::after { opacity: 1;}
				input[type="radio"]:checked + label::after { opacity: 1; }
			}
		}

		.woof_container_inner { position: relative; }

		.woof_front_toggle {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			display: block;
			overflow: hidden;

			img { display: none; }
		}
	}

}
